import { pStudio } from 'src/app/helpers/permission';
import { MenuItem } from 'src/app/models/menu-item';

export const MENU: MenuItem[] = [
  {
    title: 'menu.dashboard',
    route: '/home',
    icon: '',
    svgIcon: 'dashboard',
    child: [],
    permissions: []
  },
  {
    title: 'menu.group_student.name',
    route: '/classes',
    icon: '',
    svgIcon: 'classes-alt',
    permissions: [],
    child: [
      {
        title: 'generate.name',
        route: ['student-group/generation'],
        icon: '',
        svgIcon: 'classes-alt',
        permissions: []
      },
      {
        title: 'group.name',
        route: ['student-group/group'],
        icon: '',
        svgIcon: 'classes-alt',
        permissions: []
      },
      {
        title: 'course.students_courses_enrollment',
        route: ['/student/course'],
        icon: '',
        svgIcon: 'listing',
        permissions: []
      },
    ]
  },
  {
    title: 'menu.certificate',
    route: '/certificate',
    icon: '',
    svgIcon: 'dashboard',
    child: [],
    permissions: []
  },
  {
    title: 'menu.scholarship',
    route: '/classes',
    icon: 'school',
    svgIcon: '',
    permissions: [],
    child: [
      {
        title: 'student.name',
        route: ['scholarship/student'],
        icon: '',
        svgIcon: '',
        permissions: [pStudio.student.read]
      },
      {
        title: 'group.name',
        route: ['scholarship/group'],
        icon: '',
        svgIcon: '',
        permissions: [pStudio.s15Course.read]
      },
      {
        title: 'attendance.record',
        route: ['scholarship/attendance-record'],
        icon: '',
        svgIcon: '',
        permissions: [pStudio.attendanceList.read]
      },
      {
        title: 'attendance.submit_monthly_attendance',
        route: ['scholarship/attendance'],
        icon: '',
        svgIcon: '',
        permissions: [pStudio.attendanceSubmit.read]
      },
      {
        title: 'request_certificate.name',
        route: ['scholarship/request-certificate'],
        icon: '',
        svgIcon: '',
        permissions: [pStudio.requestCertificate.read]
      }
    ]
  },
  // {
  //   title: 'request_certificate.name',
  //   route: '/request-certificate',
  //   icon: '',
  //   svgIcon: 'listing',
  //   child: [],
  //   permissions: []
  // },
  {
    title: 'student.name',
    route: '',
    icon: '',
    svgIcon: 'students',
    permissions: [],
    child: [
      {
        title: 'student.student_list',
        route: ['/student/list'],
        icon: '',
        svgIcon: 'listing',
        permissions: [pStudio.student.read]
      },
      {
        title: 'student.import_student',
        route: ['/student/import'],
        icon: '',
        svgIcon: 'import',
        permissions: [pStudio.fileImport.read, pStudio.fileImport.write]
      },
      {
        title: 'student.enrollment.name',
        route: ['/student/enrollment'],
        icon: '',
        svgIcon: 'listing',
        permissions: [pStudio.studentEnrolRequest.read]
      }
      // {
      //   title: 'graduated.name',
      //   route: ['/student/graduated'],
      //   icon: '',
      //   svgIcon: 'import',
      //   permissions: []
      // }
    ]
  },
  {
    title: 'staff.name',
    route: 'staffs',
    icon: '',
    svgIcon: 'staff-alt',
    permissions: [],
    child: [
      {
        title: 'staff.list',
        route: ['/staff/list'],
        icon: '',
        svgIcon: 'listing',
        permissions: [pStudio.staff.read]
      },
      {
        title: 'staff.import',
        route: ['/staff/import'],
        icon: '',
        svgIcon: 'import',
        permissions: [pStudio.fileImport.readStaff]
      }
    ]
  },
  {
    title: 'attendance.name',
    route: '',
    icon: '',
    svgIcon: 'attendances',
    permissions: [],
    child: [
      {
        title: 'student.students_attendance',
        route: ['/attendance/student'],
        icon: '',
        svgIcon: 'students',
        permissions: [pStudio.attendanceList.read]
      },
      {
        title: 'staff.staff_attendances',
        route: ['/attendance/staff'],
        icon: '',
        svgIcon: 'students',
        permissions: [pStudio.attendanceStaff.read]
      },
      // {
      //   title: 'request_list.name',
      //   route: ['/attendance/student-request'],
      //   icon: '',
      //   svgIcon: 'students',
      //   permissions: [pStudio.permissionRequest.read]
      // },
      {
        title: 'request_list.name',
        route: ['/attendance/student-request'],
        icon: '',
        svgIcon: 'students',
        permissions: [pStudio.permissionRequest.read && pStudio.staffRequestPermission.read]
      }
      // {
      //   title: 'staff.staff_request',
      //   route: ['/attendance/staff-request'],
      //   icon: '',
      //   svgIcon: 'students',
      //   permissions: []
      // }
    ]
  },
  {
    title: 'newsboard.name',
    route: '/newsboard',
    icon: '',
    svgIcon: 'announcement',
    permissions: [],
    child: [
      {
        title: 'announcement.name',
        route: ['/newsboard/announcement'],
        icon: '',
        svgIcon: 'announcement',
        permissions: [pStudio.announcement.read]
      },
      {
        title: 'calendar.name',
        route: ['/newsboard/calendar'],
        icon: '',
        svgIcon: 'filter-date-alt',
        permissions: [pStudio.calendar.read]
      }
    ]
  },
  {
    title: 'payment.name',
    route: '/payment',
    icon: '',
    svgIcon: 'payment',
    permissions: [],
    child: [
      {
        title: 'template.template_list',
        route: ['/payment/template'],
        icon: '',
        svgIcon: 'payment',
        permissions: [pStudio.paymentTemplate.read]
      },
      {
        title: 'payment.payment_list',
        route: ['/payment/list'],
        icon: '',
        svgIcon: 'payment',
        permissions: [pStudio.paymentList.read]
      }
    ]
  },
  {
    title: 'report.name',
    route: '/report',
    icon: '',
    svgIcon: 'report',
    child: [
      {
        title: 'ចំនួនសិស្សវគ្គវែងតាមជំនាញ',
        route: ['/report/student/long-course'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.report.studentBySkill]
      },
      {
        title: 'ចំនួនសិស្សវគ្គខ្លីតាមជំនាញ',
        route: ['/report/student/short-course'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.report.shortCourseBySkill]
      },
      {
        title: 'ចំនួនសិស្សបញ្ចប់ការសិក្សា',
        route: ['/report/student/graduated'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.report.graduatedStudent]
      },
      {
        title: 'ចំនួនសិស្សជាប់ធ្លាក់',
        route: ['/report/student/pass-fail'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.report.longCoursePassFailed]
      }
    ],
    permissions: []
  },
  {
    title: 'report.s15',
    route: '/report-s15',
    icon: '',
    svgIcon: 'report',
    child: [
      {
        title: 'ចំនួនចុះឈ្មោះតាមគ្រឹះស្ថាន',
        route: ['/report-s15/enrollment'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.reportS15.adminDataStudentApply]
      },
      {
        title: 'ចំនួនចុះឈ្មោះតាមជំនាញ',
        route: ['/report-s15/enrollment-major'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.reportS15.adminDataStudentApply]
      },
      {
        title: 'ចំនួនចុះឈ្មោះ',
        route: ['/report-s15/enrollment-by-all'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.reportS15.adminDataStudentApply]
      },
      {
        title: 'ចំនួនអនុម័តតាមគ្រឹះស្ថាន',
        route: ['/report-s15/approval'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.reportS15.adminDataApprovedCount]
      },
      {
        title: 'ចំនួនអនុម័តតាមជំនាញ',
        route: ['/report-s15/approval-major'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.reportS15.adminDataApprovedCount]
      },
      {
        title: 'ចំនួនអនុម័ត',
        route: ['/report-s15/approval-by-all'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.reportS15.adminDataApprovedCount]
      },
      {
        title: 'ស្ថានភាពសិក្សាតាមគ្រឹះស្ថាន',
        route: ['/report-s15/status-by-school'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.reportS15.adminDataApprovedCount]
      },
      {
        title: 'ស្ថានភាពសិក្សាតាមជំនាញ',
        route: ['/report-s15/status-by-major'],
        icon: '',
        svgIcon: 'pie-chart',
        permissions: [pStudio.reportS15.adminDataApprovedCount]
      }
    ],
    permissions: []
  },
  {
    title: 'feedback.name',
    route: '/feedback',
    icon: '',
    svgIcon: 'feedback',
    child: [
      {
        title: 'student_feedback.name',
        route: ['/feedback/student'],
        icon: '',
        svgIcon: 'students',
        permissions: [pStudio.feedback.adminRead]
      },
      {
        title: 'staff_feedback.name',
        route: ['/feedback/staff'],
        icon: '',
        svgIcon: 'students',
        permissions: [pStudio.feedback.adminRead]
      }
    ],
    permissions: [pStudio.feedback.adminRead]
  },
  {
    title: 'development.name',
    route: '/development-partnership',
    icon: '',
    svgIcon: 'feedback',
    child: [
      {
        title: 'partnership.name',
        route: ['/development-partnership/partnership'],
        icon: '',
        svgIcon: 'students',
        permissions: [pStudio.developmentPartner.read]
      },
      {
        title: 'project.name',
        route: ['/development-partnership/planning'],
        icon: '',
        svgIcon: 'students',
        permissions: [pStudio.developmentPlan.read]
      }
    ],
    permissions: []
  },
  {
    title: 'inventory.name',
    route: '/inventory',
    icon: '',
    svgIcon: 'inventory',
    child: [
      {
        title: 'category.name',
        route: ['/inventory/category'],
        icon: '',
        svgIcon: 'students',
        permissions: [pStudio.inventoryCategory.read]
      },
      {
        title: 'inventory.inventory_list',
        route: ['/inventory/list'],
        icon: '',
        svgIcon: 'students',
        permissions: [pStudio.inventoryItem.read]
      }
    ],
    permissions: []
  },
  {
    title: 'menu.setting.name',
    route: '/setting',
    icon: '',
    svgIcon: 'setting',
    child: [
      {
        title: 'academic.name',
        route: ['/settings/about-of-study'],
        icon: '',
        svgIcon: 'admission',
        permissions: [
          pStudio.degree.read,
          pStudio.faculty.read,
          pStudio.department.read,
          pStudio.program.read,
          pStudio.subject.read
        ]
      },
      {
        title: 'academic_program.name',
        route: ['/settings/academic-program'],
        icon: '',
        svgIcon: 'school',
        permissions: [pStudio.academicProgram.read]
      },
      {
        title: 'grading.name',
        route: ['/settings/grading'],
        icon: '',
        svgIcon: 'school',
        permissions: [pStudio.academicProgram.read]
      },
      {
        title: 'shift.name',
        route: ['/settings/shift'],
        icon: '',
        svgIcon: 'school',
        permissions: [pStudio.school.read]
      },
      {
        title: 'class_room.name',
        route: ['/settings/about-school/classroom'],
        icon: '',
        svgIcon: 'school',
        permissions: [pStudio.building.read, pStudio.room.read]
      },
      {
        title: 'school.school_profile',
        route: ['/settings/about-school/school-info'],
        icon: '',
        svgIcon: 'school',
        permissions: [pStudio.school.read]
      }
      // {
      //   title: 'By Ministry',
      //   route: ['/settings/ministry'],
      //   icon: '',
      //   svgIcon: 'school',
      //   permissions: [pStudio.academicYear.read, pStudio.title.read]
      // },
      // {
      //   title: 'Calendar',
      //   route: ['/settings/calendar'],
      //   icon: '',
      //   svgIcon: 'filter-date',
      //   permissions: [pStudio.calendar.read]
      // },
      // {
      //   title: 'History Log',
      //   route: ['/settings/history-log'],
      //   icon: '',
      //   svgIcon: 'log',
      //   permissions: [pStudio.userLog.read, pStudio.schoolLog.read]
      // }
    ],
    permissions: []
  }
];
